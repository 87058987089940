import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';

import { Error, Loader } from '@heltti/components';

import {
    EditEmploymentMutation,
    EditEmploymentMutationVariables,
    GetEmploymentQuery,
    GetEmploymentQueryVariables
} from '../graphql-schema';

import { EmploymentForm, EmploymentFormData } from './EmploymentForm';
import { employmentFragment, companyFragment } from '../data/fragments';
import { GET_COMPANY_MEMBERS } from './EmploymentList';
import { useLoadingQuery } from '../hooks/loading-query';

const QUERY_GET_EMPLOYMENT = gql`
    query GetEmployment($employmentId: ID!) {
        root {
            employment(id: $employmentId) {
                ...EmploymentFragment
            }
        }
    }

    ${employmentFragment}
`;

const MUTATION_EDIT_EMPLOYMENT = gql`
    mutation EditEmployment($data: EmploymentEditMutationInput!) {
        employmentEdit(input: $data) {
            employment {
                ...EmploymentFragment
            }
            company {
                ...CompanyFragment
            }
        }
    }

    ${employmentFragment}
    ${companyFragment}
`;

export type EmploymentEditFormProps = { employmentId: string; close: () => void };

export const EmploymentEditForm: FunctionComponent<EmploymentEditFormProps> = ({ employmentId, close }) => {
    const {
        data,
        loading: queryLoading,
        error: queryError
    } = useLoadingQuery<GetEmploymentQuery, GetEmploymentQueryVariables>(QUERY_GET_EMPLOYMENT, {
        variables: { employmentId }
    });

    const [editEmployment, { loading: mutationLoading, error: mutationError }] = useMutation<
        EditEmploymentMutation,
        EditEmploymentMutationVariables
    >(MUTATION_EDIT_EMPLOYMENT, { onCompleted: () => close() });

    if (queryLoading || !data?.root?.employment) {
        return <Loader />;
    }

    if (queryError) {
        return <Error />;
    }

    const onSubmit = async (values: EmploymentFormData) => {
        const dateFormat = (date: Date | null) => (date ? format(date, 'yyyy-MM-dd') : null);
        const {
            startDate,
            endDate,
            location,
            department,
            position,
            superior,
            billingCategory,
            memberLanguageId,
            contactDetailsEmail,
            contactDetailsPhone,
            contactDetailsAddress1,
            contactDetailsAddress2,
            contactDetailsCity,
            contactDetailsZip
        } = values;

        const data = {
            employmentId,
            startDate: dateFormat(startDate) as string,
            endDate: dateFormat(endDate),
            location,
            department,
            position,
            superior,
            email: contactDetailsEmail,
            phone: contactDetailsPhone,
            address1: contactDetailsAddress1,
            address2: contactDetailsAddress2,
            city: contactDetailsCity,
            zip: contactDetailsZip,
            language: btoa(memberLanguageId),
            billingCategory
        };

        if (!mutationLoading) {
            try {
                await editEmployment({
                    variables: {
                        data
                    },
                    refetchQueries: () => [GET_COMPANY_MEMBERS],
                    awaitRefetchQueries: true
                });
            } catch (error) {
                Sentry.captureMessage(`Employment edit mutation failed: ${error}`, 'warning');
            }
        }
    };

    const onCancel = () => {
        close();
        return Promise.resolve();
    };

    const { startDate, endDate, position, superior, location, department, billingCategory, member, contactDetails } =
        data.root.employment;

    const initialValues: EmploymentFormData = {
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : null,
        position: position ?? '',
        superior: superior ?? '',
        location: location ?? '',
        department: department?.id,
        billingCategory: billingCategory ?? '',
        memberFirstName: member.firstName,
        memberLastName: member.lastName,
        memberSsn: member.ssn ?? '',
        memberLanguageId: atob(member.language.id),
        contactDetailsPhone: contactDetails?.phone ?? '',
        contactDetailsEmail: contactDetails?.email ?? '',
        contactDetailsAddress1: contactDetails?.address1 ?? '',
        contactDetailsAddress2: contactDetails?.address2 ?? '',
        contactDetailsZip: contactDetails?.zip ?? '',
        contactDetailsCity: contactDetails?.city ?? ''
    };

    return (
        <>
            {mutationError ? <Error description={mutationError.message} /> : null}
            <EmploymentForm
                validate
                mode="edit"
                open={employmentId !== undefined}
                loading={mutationLoading}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </>
    );
};
