import { auth, AuthState } from './auth';
import password, { PasswordResetState } from './password';

export type RootState = {
    auth: AuthState;
    password: PasswordResetState;
};

export * from './auth';

export const reducers = {
    auth,
    password
};
