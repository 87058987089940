import React, { useState } from 'react';
import { useDebounce } from '@react-hook/debounce';
import { useIntl } from 'react-intl';
import { Redirect, useParams } from 'react-router';
import { gql } from '@apollo/client';

import { Card, Form, Header, Icon, Input } from 'semantic-ui-react';
import { Button, Content, Error, Loader } from '@heltti/components';

import { AppNavigationBar } from '../../components/AppNavigationBar';
import { AppPrivateRouteParams } from '../../components/App';
import { EmploymentList } from '../../components/EmploymentList';
import { EmploymentSummary } from '../../components/EmploymentSummary';
import { useUserProfile } from '../../context/UserProfile';
import { EmploymentAddForm } from '../../components/EmploymentAddForm';
import t from '../../translations';
import { DataIntegrationBanner } from '../../components/DataIntegrationBanner';
import { GetCompanyIntegrationDetailsQuery } from '../../graphql-schema';

import './employments.less';
import { useLoadingQuery } from '../../hooks/loading-query';

export const QUERY_GET_COMPANY = gql`
    query GetCompanyIntegrationDetails($companyId: ID!) {
        root {
            company(id: $companyId) {
                id
                memberDataIntegrationEnabled
                sickLeaveDataIntegrationEnabled
            }
        }
    }
`;

export const EmploymentsPage = () => {
    const intl = useIntl();
    const { companyAccess } = useUserProfile()!;
    const { companyId } = useParams<AppPrivateRouteParams>();
    const [query, setQuery] = useDebounce<string>('', 200);
    const [addEmploymentOpen, setAddEmploymentOpen] = useState<boolean>(false);

    const { data, loading, error } = useLoadingQuery<GetCompanyIntegrationDetailsQuery>(QUERY_GET_COMPANY, {
        variables: { companyId }
    });

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <Error />;
    }

    const memberDataIntegrationEnabled = data?.root?.company?.memberDataIntegrationEnabled;
    const sickLeaveIntegrationEnabled = data?.root?.company?.sickLeaveDataIntegrationEnabled;

    const employmentsEditable = !memberDataIntegrationEnabled;

    return companyId in companyAccess ? (
        <div id="app">
            <AppNavigationBar />
            <div id="contentScroll">
                <div id="employments">
                    <Content id="employments" containerWidth="wide">
                        <Header as="h3" style={{ paddingLeft: '20px' }}>
                            {intl.formatMessage(t.employments)}
                        </Header>

                        <Card>
                            <Card.Content>
                                <Header as="h4">{intl.formatMessage(t.companyBillingtypeSummary)}</Header>
                                <EmploymentSummary companyId={companyId} />
                            </Card.Content>
                        </Card>

                        <Card fluid>
                            <Card.Content>
                                {memberDataIntegrationEnabled && <DataIntegrationBanner type="members" />}
                                {sickLeaveIntegrationEnabled && <DataIntegrationBanner type="sickLeave" />}
                                <Form>
                                    <Form.Group>
                                        <Form.Field width="4">
                                            <Input
                                                icon={{ name: 'search', link: true }}
                                                placeholder={intl.formatMessage(t.employmentSearch)}
                                                onChange={({ target: { value } }) => setQuery(value)}
                                            />
                                        </Form.Field>
                                        <Form.Field width="12">
                                            {addEmploymentOpen ? (
                                                <EmploymentAddForm
                                                    companyId={companyId}
                                                    open={addEmploymentOpen}
                                                    close={() => setAddEmploymentOpen(false)}
                                                />
                                            ) : null}
                                            {employmentsEditable && (
                                                <Button floated="right" onClick={() => setAddEmploymentOpen(true)}>
                                                    <Icon name="plus circle" />
                                                    {intl.formatMessage({ id: 'employees.employment.create' })}
                                                </Button>
                                            )}
                                        </Form.Field>
                                    </Form.Group>
                                    <EmploymentList
                                        editable={employmentsEditable}
                                        companyId={companyId}
                                        query={query}
                                    />
                                </Form>
                            </Card.Content>
                        </Card>
                    </Content>
                </div>
            </div>
        </div>
    ) : (
        <Redirect to="/" />
    );
};
