import { useCallback } from 'react';
import { QueryHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { default as useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useCoreLoadingQuery } from '@heltti/common';
import { path } from 'rambda';

export type PagingVariables = {
    orderBy: string;
    descending?: boolean;
    first?: number;
    query?: string;
};

type Edge = {
    node: any;
};

type PageInfo = {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
};

type Node = {
    edges: Edge[];
    pageInfo: PageInfo;
};

export const useInfiniteLoadingQuery = function <TData = any, TVariables = any, TInfiniteScrollRef = HTMLDivElement>(
    document: DocumentNode,
    nodePath: string,
    paging: PagingVariables,
    options?: QueryHookOptions<TData, TVariables & PagingVariables>
) {
    const { first = 10, orderBy, descending, query } = paging;

    const result = useCoreLoadingQuery<
        TData,
        TVariables & PagingVariables,
        TData,
        TVariables & PagingVariables>(document, {
        fetchPolicy: 'cache-and-network',
        ...options,
        variables: { ...options?.variables, first, orderBy, descending, query }
    });
    const { loading, data, fetchMore, error } = result;
    const pageInfo = path<Node>(nodePath, data)?.pageInfo;
    const { hasNextPage = false, endCursor } = pageInfo ?? {};

    const onLoadMore = useCallback(() => {
        fetchMore({
            variables: {
                ...options?.variables,
                orderBy,
                descending,
                query,
                first,
                after: endCursor
            }
        });
    }, [fetchMore, options?.variables, orderBy, descending, query, first, endCursor]);

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore,
        disabled: !!error
    });

    return { ...result, sentryRef };
};
