import { forgotPassword as apiForgotPassword, resetPassword as apiResetPassword } from '../api';
import { AnyAction } from 'redux';

export const FORGOT_PASSWORD = 'heltti/password/forgot';
export const RESET_PASSWORD = 'heltti/password/reset';
export const RESET_PASSWORD_SUCCESS = 'heltti/password/resetSuccess';
export const RESET_PASSWORD_FAILURE = 'heltti/password/resetFailure';
export const RESET_PASSWORD_CLEAR = 'heltti/password/resetClear';

export interface PasswordResetState {
    isLoading: boolean;
    hasError: boolean;
}

function getInitialState() {
    return {
        isLoading: false,
        hasError: false
    };
}

export default function password(state: PasswordResetState = getInitialState(), action: AnyAction): PasswordResetState {
    switch (action.type) {
        case RESET_PASSWORD_CLEAR:
            return getInitialState();

        case RESET_PASSWORD:
            return {
                ...state,
                isLoading: true
            };

        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasError: true
            };

        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}

export function forgotPassword(email: string) {
    return (dispatch) => {
        return apiForgotPassword(email).then((result) => {
            dispatch({ type: FORGOT_PASSWORD, payload: { email } });

            return result;
        });
    };
}

export function resetPassword(
    smsToken: string,
    token: string,
    email: string,
    oldPassword: string,
    newPassword: string
) {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD });

        return apiResetPassword(smsToken, token, email, oldPassword, newPassword)
            .then(() => dispatch({ type: RESET_PASSWORD_SUCCESS }))
            .catch((e) => {
                dispatch({ type: RESET_PASSWORD_FAILURE });

                throw e;
            });
    };
}

export function clearResetPassword() {
    return (dispatch) => dispatch({ type: RESET_PASSWORD_CLEAR });
}
