import React, { Component } from 'react';
import { Button, Form, Input, Message } from 'semantic-ui-react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import t from '../../translations';
import { clearResetPassword, resetPassword } from '../../ducks/password';
import { default as PasswordIndicator, isPasswordValid } from '../../components/PasswordIndicator';
import { RootState } from '../../ducks';

type Props = WrappedComponentProps & {
    email: string;
    token: string;
    resetPassword: Function;
    clearResetPassword: Function;
    isLoading: boolean;
    hasError: boolean;
    onSuccess: Function;
};

interface State {
    smsToken: string;
    password: string;
    newPassword: string;
    verifyPassword: string;
}

class PasswordResetForm extends Component<Props, State> {
    public state: State = {
        smsToken: '',
        password: '',
        newPassword: '',
        verifyPassword: ''
    };

    public componentDidMount() {
        this.props.clearResetPassword();
    }

    private handleChange = (e: any, { value }: any) => {
        this.setState({ [e.target.name]: value } as State);
    };

    private handleSubmit = () => {
        const { token, email, onSuccess } = this.props;
        const { smsToken, password, newPassword } = this.state;

        this.props
            .resetPassword(smsToken, decodeURIComponent(token), decodeURIComponent(email), password, newPassword)
            .then(onSuccess);
    };

    public resetWithToken = () => {
        const { email, token } = this.props;

        return !!email && !!token;
    };

    public render() {
        const { intl, isLoading, hasError } = this.props;
        const { newPassword, verifyPassword, password, smsToken } = this.state;

        const isPasswordInvalid = !isPasswordValid(newPassword, verifyPassword);

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading} autoComplete="off">
                <Form.Field>
                    {this.resetWithToken() ? (
                        <Input
                            type="number"
                            name="smsToken"
                            value={this.state.smsToken}
                            onChange={this.handleChange}
                            autoComplete="new-password"
                            inputMode="numeric"
                            minLength="6"
                            maxLength="6"
                            placeholder={intl.formatMessage(t.resetPasswordSmsToken)}
                        />
                    ) : (
                        <Input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            autoComplete="current-password"
                            placeholder={intl.formatMessage(t.resetPasswordCurrentPassword)}
                        />
                    )}
                </Form.Field>

                <Form.Field>
                    <Input
                        type="password"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={this.handleChange}
                        placeholder={intl.formatMessage(t.resetPasswordInput)}
                        autoComplete="new-password"
                    />
                </Form.Field>

                <Form.Field>
                    <Input
                        type="password"
                        name="verifyPassword"
                        value={this.state.verifyPassword}
                        onChange={this.handleChange}
                        placeholder={intl.formatMessage(t.resetPasswordVerify)}
                        autoComplete="new-password"
                    />
                </Form.Field>

                <Button fluid primary size="large" disabled={isPasswordInvalid || (!smsToken && !password)}>
                    {intl.formatMessage(t.resetPasswordSet)}
                </Button>

                <PasswordIndicator password={this.state.newPassword} passwordAgain={this.state.verifyPassword} />

                <Message error visible={hasError}>
                    {intl.formatMessage(t.resetPasswordFailure)}
                </Message>
            </Form>
        );
    }
}

export default connect(
    (state: RootState) => ({
        isLoading: state.password.isLoading,
        hasError: state.password.hasError
    }),
    {
        resetPassword,
        clearResetPassword
    }
)(injectIntl(PasswordResetForm));
