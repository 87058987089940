import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Grid } from 'semantic-ui-react';

import PasswordResetForm from './PasswordResetForm';
import t from '../../translations';
import { Content } from '@heltti/components';
import { SCREEN_WIDTH_MAX_MOBILE } from '../../constants';
import { Translation } from '../../components/Message';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import backgroundImage from '../../assets/images/heltti-cover.jpg';

type Props = RouteComponentProps<any>;

export class PasswordReset extends Component<Props> {
    private handleSuccess = () => {
        this.props.history.push('/');
    };

    public render() {
        const { params } = this.props.match;

        const isMobile = window.innerWidth <= SCREEN_WIDTH_MAX_MOBILE;

        return (
            <div id="app">
                <div id="contentScroll">
                    <div id="password-reset">
                        <Content id="password-reset-content">
                            <Grid id="loginGrid">
                                <Grid.Row columns={isMobile ? 1 : 2}>
                                    <Grid.Column textAlign="center" verticalAlign="middle">
                                        <div id="loginFormContainer">
                                            <div className="loginFormContent">
                                                <h1>
                                                    <Translation message={t.resetPasswordTitle} />
                                                </h1>

                                                <PasswordResetForm
                                                    email={params.email}
                                                    token={params.token}
                                                    onSuccess={this.handleSuccess}
                                                />
                                            </div>
                                        </div>
                                    </Grid.Column>

                                    {!isMobile && (
                                        <Grid.Column>
                                            <div
                                                id="pageHeader"
                                                style={{
                                                    backgroundImage: `url(${backgroundImage})`,
                                                    height: '100%'
                                                }}
                                            />
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Content>
                    </div>
                </div>
            </div>
        );
    }
}
