import React, { FunctionComponent } from 'react';
import { gql, useMutation } from '@apollo/client';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';

import { Error } from '@heltti/components';

import { companyFragment, employmentFragment } from '../data/fragments';

import { AddEmploymentMutation, AddEmploymentMutationVariables } from '../graphql-schema';
import { EmploymentForm, EmploymentFormData } from './EmploymentForm';
import { GET_COMPANY_MEMBERS } from './EmploymentList';

const MUTATION_ADD_EMPLOYMENT = gql`
    mutation AddEmployment($data: EmploymentAddMutationInput!) {
        employmentAdd(input: $data) {
            employment {
                ...EmploymentFragment
            }
            company {
                ...CompanyFragment
            }
        }
    }
    ${companyFragment}
    ${employmentFragment}
`;

export type EmploymentAddFormProps = { companyId: string; open: boolean; close: () => void };

export const EmploymentAddForm: FunctionComponent<EmploymentAddFormProps> = ({ companyId, open, close }) => {
    const [addEmployment, { loading, error }] = useMutation<AddEmploymentMutation, AddEmploymentMutationVariables>(
        MUTATION_ADD_EMPLOYMENT,
        { onCompleted: () => close() }
    );

    const onSubmit = async (values: EmploymentFormData) => {
        const dateFormat = (date: Date | null) => (date ? format(date, 'yyyy-MM-dd') : null);
        const {
            startDate,
            endDate,
            location,
            position,
            superior,
            department,
            billingCategory,
            memberFirstName,
            memberLastName,
            memberSsn,
            memberLanguageId,
            contactDetailsEmail,
            contactDetailsPhone,
            contactDetailsAddress1,
            contactDetailsAddress2,
            contactDetailsCity,
            contactDetailsZip
        } = values;

        const data = {
            companyId,
            firstName: memberFirstName,
            lastName: memberLastName,
            ssn: memberSsn,
            email: contactDetailsEmail,
            phone: contactDetailsPhone,
            address1: contactDetailsAddress1,
            address2: contactDetailsAddress2,
            city: contactDetailsCity,
            zip: contactDetailsZip,
            startDate: dateFormat(startDate) as string,
            endDate: dateFormat(endDate),
            location,
            position,
            superior,
            department,
            language: btoa(memberLanguageId),
            billingCategory
        };

        if (!loading) {
            try {
                // See https://stackoverflow.com/a/59472340 for a good explanation on mutation errors
                await addEmployment({
                    variables: {
                        data
                    },
                    // See discussion at https://github.com/apollographql/apollo-client/issues/3633 on why it is better
                    // to use refetch function and by query name rather than by query node and variables.
                    refetchQueries: () => [GET_COMPANY_MEMBERS],
                    awaitRefetchQueries: true
                });
            } catch (error) {
                Sentry.captureMessage(`Add employment mutation failed: ${error}`, 'warning');
            }
        }
    };

    const onCancel = () => {
        close();
        return Promise.resolve();
    };

    const initialValues: EmploymentFormData = {
        startDate: new Date(),
        endDate: null,
        position: '',
        superior: '',
        location: '',
        department: '',
        billingCategory: '',
        memberFirstName: '',
        memberLastName: '',
        memberSsn: '',
        memberLanguageId: '',
        contactDetailsPhone: '',
        contactDetailsEmail: '',
        contactDetailsAddress1: '',
        contactDetailsAddress2: '',
        contactDetailsCity: '',
        contactDetailsZip: ''
    };

    return (
        <>
            {error ? <Error description={error.message} /> : null}

            <EmploymentForm
                mode="create"
                open={open}
                loading={loading}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
            />
        </>
    );
};
