import { SemanticICONS } from 'semantic-ui-react';

const ICONS: { [key: string]: SemanticICONS } = {
    pdf: 'file pdf outline',
    doc: 'file word outline',
    docx: 'file word outline',
    ppt: 'file powerpoint outline',
    pptx: 'file powerpoint outline',
    xls: 'file excel outline',
    xlsx: 'file excel outline',
    png: 'file image outline',
    jpeg: 'file image outline',
    jpg: 'file image outline',
    zip: 'file archive outline',
    txt: 'file alternate outline',
    rtf: 'file alternate outline',
    none: 'file outline'
};

export const fileExtensionToIconName = (filename: string): SemanticICONS => {
    const extension = filename.split('.').pop()?.toLowerCase() ?? 'none';
    return ICONS[extension] ?? 'file outline';
};
