import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Divider, DropdownProps, Form, Modal, DropdownItemProps } from 'semantic-ui-react';
import { Button } from '@heltti/components';

import { Translation, translations } from '../translations';
import { FinvoiceRouterNode } from '../graphql-schema';

interface FormProps {
    finvoiceRouters: FinvoiceRouterNode[];
    initialValues: InvoicingFormData;
    loading?: boolean;
    onSubmit: (values: InvoicingFormData) => Promise<void>;
    validate?: boolean;
}

export type InvoicingFormData = {
    address1?: string | null;
    address2?: string | null;
    zip?: string | null;
    city?: string | null;
    email?: string | null;
    finvoiceAddress?: string | null;
    finvoiceRouterCode?: string | null;
    showMembersOnInvoiceAttachment?: boolean;
    sendAttachmentWithInvoice?: boolean;
};

const schema = () => {
    return yup.object().shape({
        address1: yup.string().required(),
        address2: yup.string().nullable(),
        zip: yup.string().matches(/^\d{5}$/),
        city: yup.string().required(),

        email: yup.string().email().nullable(),
        finvoiceAddress: yup.string().nullable(),
        finvoiceRouterCode: yup.string().when('finvoiceAddress', {
            is: (values) => values && values.length > 0,
            then: yup.string().required(),
            otherwise: yup.string().nullable()
        }),

        showMembersOnInvoiceAttachment: yup.boolean()
    });
};

const finvoiceRouterOptions = (finvoiceRouters: FinvoiceRouterNode[]): DropdownItemProps[] => {
    return finvoiceRouters.reduce((accumulator, key) => {
        accumulator.push({
            value: key.code,
            text: key.name,
            description: key.code
        });

        return accumulator;
    }, [] as DropdownItemProps[]);
};

export const InvoicingForm: React.FC<FormProps> = (props) => {
    const intl = useIntl();
    const { loading, validate, initialValues, onSubmit, finvoiceRouters } = props;

    const { handleChange, validateForm, values, errors, dirty, isValid, setFieldValue, submitForm } =
        useFormik<InvoicingFormData>({
            enableReinitialize: true,
            initialValues,
            onSubmit,
            validationSchema: schema()
        });

    useEffect(() => {
        if (validate) {
            void validateForm();
        }
    }, [validate, validateForm]);

    return (
        <>
            <Modal.Content>
                <Form
                    onSubmit={() => {
                        void onSubmit(values);
                    }}
                >
                    <Form.Field>
                        <Form.Input
                            required
                            id="address1"
                            name="address1"
                            value={values.address1 ?? ''}
                            onChange={handleChange}
                            error={!!errors?.address1}
                            label={intl.formatMessage({ id: translations.address.streetAddress.id })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Form.Input
                            id="address2"
                            name="address2"
                            value={values.address2 ?? ''}
                            onChange={handleChange}
                            error={!!errors?.address2}
                        />
                    </Form.Field>

                    <Form.Group widths="equal">
                        <Form.Field>
                            <Form.Input
                                required
                                id="zip"
                                name="zip"
                                value={values.zip ?? ''}
                                onChange={handleChange}
                                error={!!errors?.zip}
                                label={intl.formatMessage({ id: translations.address.zip.id })}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                required
                                id="city"
                                name="city"
                                value={values.city ?? ''}
                                onChange={handleChange}
                                error={!!errors?.city}
                                label={intl.formatMessage({ id: translations.address.city.id })}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Divider hidden />

                    <Form.Field>
                        <Form.Input
                            id="email"
                            name="email"
                            value={values.email ?? ''}
                            onChange={handleChange}
                            error={!!errors?.email}
                            label={intl.formatMessage({ id: translations.invoicing.email.id })}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Form.Input
                            id="finvoiceAddress"
                            name="finvoiceAddress"
                            value={values.finvoiceAddress ?? ''}
                            onChange={handleChange}
                            error={!!errors?.finvoiceAddress}
                            label={intl.formatMessage({ id: translations.invoicing.finvoiceAddress.id })}
                        />
                    </Form.Field>

                    {!!values.finvoiceAddress && (
                        <Form.Field>
                            <Form.Select
                                id="finvoiceRouterCode"
                                name="finvoiceRouterCode"
                                value={values.finvoiceAddress ? values.finvoiceRouterCode ?? '' : ''}
                                options={finvoiceRouterOptions(finvoiceRouters)}
                                onChange={(_, data: DropdownProps) => {
                                    void setFieldValue('finvoiceRouterCode', data.value);
                                }}
                                label={intl.formatMessage({ id: translations.invoicing.finvoiceRouter.id })}
                                placeholder={intl.formatMessage({ id: translations.invoicing.finvoiceRouter.id })}
                                error={!!errors?.finvoiceRouterCode}
                            />
                        </Form.Field>
                    )}

                    <Divider hidden />

                    <Form.Field>
                        <Form.Checkbox
                            id="sendAttachmentWithInvoice"
                            name="sendAttachmentWithInvoice"
                            checked={values.sendAttachmentWithInvoice ?? true}
                            onChange={handleChange}
                            error={!!errors?.sendAttachmentWithInvoice}
                            label={intl.formatMessage({ id: translations.invoicing.sendAttachmentWithInvoice.id })}
                        />
                    </Form.Field>

                    <Divider hidden />

                    <Form.Field>
                        <Form.Checkbox
                            id="showMembersOnInvoiceAttachment"
                            name="showMembersOnInvoiceAttachment"
                            disabled={!values.sendAttachmentWithInvoice}
                            checked={values.showMembersOnInvoiceAttachment ?? true}
                            onChange={handleChange}
                            error={!!errors?.showMembersOnInvoiceAttachment}
                            label={intl.formatMessage({ id: translations.invoicing.showMembersOnInvoiceAttachment.id })}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    primary
                    formNoValidate
                    onClick={() => {
                        void submitForm();
                    }}
                    disabled={!dirty || !isValid}
                    loading={loading}
                >
                    <Translation for={translations.form.save} />
                </Button>
            </Modal.Actions>
        </>
    );
};
